<template>


  <template
    v-if="selectedShopifyShop && selectedShopifyShop.facebookAdAccounts && selectedShopifyShop.facebookAdAccounts.length">

    <div class="row g-5 g-xl-8 mt-3">
      <b-card>
        <template #header>
          <div class="card-title m-0 w-100 d-flex">
            <h3 class="fw-bolder m-0 pe-4 text-nowrap">Key Metrics</h3>

            <div class="pe-4">
              <date-presets-dropdown :value="datePreset"
                                     @update:value="datePreset = $event"/>
            </div>
            <div class="d-flex flex-grow-1 justify-content-end">
              <dashboard-automation-status/>
            </div>
          </div>
        </template>
        <b-row>
          <div class="col" v-for="metric in metrics" :key="metric.title">
            <metric-chart-card :chart-data="metric.data"
                               :value="metric.value"
                               :title="metric.title"
                               :diff="metric.diff"
                               :number-type="metric.numberType" />
          </div>
        </b-row>
      </b-card>

    </div>

    <div class="row g-5 g-xl-8 mt-3">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3 mb-1">Autiomations Overview</span>
            </h3>
          </div>
          <div class="card-body p-0 d-flex flex-column">
            <div class="card-p pt-5 bg-white flex-grow-1">
              <div class="row g-5 g-xl-8 mt-3">
                <div class="col" v-for="stat in actionStats">
                  <div class="card h-100 bg-light">
                    <div class="card-header flex-nowrap border-0 pt-9">
                      <div class="card-title m-0">
                        <div class="symbol symbol-45px w-45px bg-secondary me-5"><img
                          :src="stat.icon" alt="image" class="p-3"></div>
                        <span href="#" class="fs-4 fw-bold text-gray-600 m-0">{{ stat.value }} {{ stat.title }}</span>
                      </div>
                    </div>
                    <div class="card-body d-flex flex-column px-9 pt-6 pb-8">

                      <div class="d-flex align-items-center flex-wrap mb-5 mt-auto fs-6">
                        <span
                        class="svg-icon-success svg-icon svg-icon-3 me-1"><!----></span>
<!--                        <div class="text-success fw-bolder me-2">+40.5%</div>-->
<!--                        <div class="fs-2tx fw-bolder mb-3 me-5">{{ stat.value }}</div>-->
                        <div class="fw-bold text-gray-400">{{ stat.description }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <apexchart
              class="mixed-widget-3-chart card-rounded-bottom"
              :options="chartOptions"
              :series="series"
              :height="chartHeight"
              type="area"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-5 g-xl-8 mt-3">
      <ads-table />
    </div>

<!--    <div class="row g-5 g-xl-8 mt-3">-->
<!--      <dashboard-metrics/>-->
<!--    </div>-->

<!--    <div class="row g-5 g-xl-8 mt-3">-->
<!--      <div class="col-xl-4">-->
<!--        <Sales/>-->
<!--      </div>-->
<!--      <div class="col-xl-4">-->
<!--        <Roas/>-->
<!--      </div>-->
<!--      <div class="col-xl-4">-->
<!--        <CPA/>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="row g-5 g-xl-8 mt-3">-->
<!--      <div class="col-xl-12">-->
<!--        <SalesByHour/>-->
<!--      </div>-->

<!--    </div>-->

<!--    <div class="row g-5 g-xl-8 mt-3">-->
<!--      <div class="col-xl-12">-->
<!--        <CampaignTable/>-->
<!--      </div>-->
<!--    </div>-->

    <div class="row g-5 g-xl-8 mt-3">
      <div class="col-xl-12">
        <ProductsTable/>
      </div>
    </div>

<!--    <div class="row g-5 g-xl-8 mt-3">-->
<!--      <div class="col-xl-8">-->
<!--        <TopSources/>-->
<!--      </div>-->
<!--      <div class="col-xl-4">-->
<!--        <UTMTable/>-->
<!--      </div>-->
<!--    </div>-->

  </template>
  <template v-else>

    <invalid-shop/>

  </template>

</template>

<script lang="ts">
import {defineComponent} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {mapActions, mapGetters} from "vuex";
import DashboardMetrics from './dashboard/Metrics.vue';
// import DashboardActionLog from './dashboard/ActionLog.vue';
import InvalidShop from '@/components/shopify/InvalidShop.vue';
// import AdStatusesMetric from "@/views/dashboard/Metrics/AdStatusesMetric.vue";
import CPA from "@/views/dashboard/Metrics/CPA.vue";
import SalesByHour from "@/views/dashboard/Metrics/SalesByHour.vue";
import ProductsTable from "@/views/dashboard/Metrics/ProductsTable.vue";
import CampaignTable from "@/views/dashboard/Metrics/CampaignTable.vue";
import Sales from "@/views/dashboard/Metrics/Sales.vue";
import Roas from "@/views/dashboard/Metrics/Roas.vue";
import TopSources from "@/views/dashboard/Metrics/TopSources.vue";
import UTMTable from "@/views/dashboard/Metrics/UtmTable.vue";
import MetricChartCard from "@/components/cards/MetricChartCard.vue";
import MixedWidget10 from "@/unused/components/widgets/mixed/Widget10.vue";
import AdsTable from "@/views/dashboard/AdsTable.vue";


export default defineComponent({
  name: "dashboard",
  data() {
    return {
      metrics: [
        {
          title: 'ROAS',
          value: 2.15,
          diff: 0.37,
          numberType: 'integer',
          data: [1.8, 2.34, 2.13, 1.90, 2.66, 1.4, 2.19]
        },
        {
          title: 'Blended ROAS',
          value: 3.35,
          diff: -0.17,
          numberType: 'integer',
          data: [2.31, 4.45, 2.54, 1.06, 3.9, 1.32, 5.82]
        },
        {
          title: 'Spend',
          value: 1048,
          diff: 1.32,
          numberType: 'money',
          data: [816.12, 1344.59, 935.19, 938.37, 833.49, 698.51, 1017.89]
        },
        {
          title: 'NCPA',
          value: 34.43,
          diff: 0.87,
          numberType: 'money',
          data: [51.61, 24.54, 50.17, 28.14, 26.69, 57.19, 56.63]
        },
        {
          title: 'CPA',
          value: 20.34,
          diff: -0.23,
          numberType: 'money',
          data: [51.61, 24.54, 50.17, 28.14, 26.69, 57.19, 56.63]
        },
        {
          title: 'Orders',
          value: 395,
          diff: 2.43,
          numberType: 'integer',
          data: [167, 110, 137, 201, 457, 429, 242]
        }
      ],
      actionStats: [
        {
          title: 'Paused',
          icon: '/media/svg/icons/Media/Pause.svg',
          value: 7,
          description: '$32K Spend Last 7 days'
        },
        {
          title: 'Resumed',
          icon: '/media/svg/icons/Media/Play.svg',
          value: 15,
          description: '$13K Spend Last 7 days'
        },
        {
          title: 'Budget change',
          icon: '/media/svg/icons/Shopping/Dollar.svg',
          value: 4,
          description: '$3K Spend Last 7 days'
        },
        {
          title: 'Bid change',
          icon: '/media/svg/icons/Shopping/Dollar.svg',
          value: 2,
          description: '$18K Spend Last 7 days'
        },
      ]
    }
  },
  components: {
    DashboardMetrics,
    InvalidShop,
    // AdStatusesMetric,
    CPA,
    SalesByHour,
    ProductsTable,
    CampaignTable,
    Sales,
    Roas,
    TopSources,
    UTMTable,
    MetricChartCard,
    AdsTable
  },

  computed: {
    ...mapGetters({
      selectedShopifyShop: 'shopify/shops/selected',
    }),
  },

  methods: {
    ...mapActions({
      fetchStats: 'shopify/shops/dashboard/fetchStats',
      fetchKeyMetrics: 'shopify/shops/dashboard/fetchKeyMetrics'
    }),
  },

  watch: {
    'selectedShopifyShop.id'() {
      this.fetchStats({force: true});
      this.fetchKeyMetrics();
    }
  },

  mounted() {
    setCurrentPageTitle("Dashboard");
    this.fetchStats({force: true});
    this.fetchKeyMetrics();
  },
});
</script>
