
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-widget-12",
  components: {
  },
  props: {
    widgetClasses: String
  },
  setup() {
    const list = [
      {
        ad: {
          image: "https://picsum.photos/id/1/100/100",
          name: "Slim T - Sales Catalog",
        },
        stats: {
          spend: 5400,
          roas: 1.74,
          cpa: 107
        },
        action: {
          name: "Paused",
        }
      },
      {
        ad: {
          image: "https://picsum.photos/id/2/100/100",
          name: "| Type:quick slideshow | Other::Nipkit Casual Shorts | Product_id:##1961429434410## |",
        },
        stats: {
          spend: 2894,
          roas: 2.45,
          cpa: 83
        },
        action: {
          name: "Started",
        }
      },
      {
        ad: {
          image: "https://picsum.photos/id/3/100/100",
          name: "| type:slideshow | - Copy",
        },
        stats: {
          spend: 852,
          roas: 3.74,
          cpa: 38
        },
        action: {
          name: "Started",
        }
      },
      {
        ad: {
          image: "https://picsum.photos/id/4/100/100",
          name: "image - Copy",
        },
        stats: {
          spend: 2984,
          roas: 1.93,
          cpa: 150
        },
        action: {
          name: "Paused",
        }
      },
      {
        ad: {
          image: "https://picsum.photos/id/5/100/100",
          name: "ns | Burger Embroidered T-Shirt",
        },
        stats: {
          spend: 92,
          roas: 5.33,
          cpa: 12
        },
        action: {
          name: "Paused",
        }
      },
      {
        ad: {
          image: "https://picsum.photos/id/6/100/100",
          name: "all without STOP - Copy 2",
        },
        stats: {
          spend: 8397,
          roas: 2.44,
          cpa: 48
        },
        action: {
          name: "Started",
        }
      },
      {
        ad: {
          image: "https://picsum.photos/id/7/100/100",
          name: "image 1 Cassio Linen Blend Shirt",
        },
        stats: {
          spend: 29572,
          roas: 3.29,
          cpa: 49
        },
        action: {
          name: "Paused",
        }
      },
    ];

    return {
      list
    };
  }
});
