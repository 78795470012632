<template>
  <!--begin::Tables Widget 12-->
  <div :class="widgetClasses" class="card">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Recent Actions</span>

        <span class="text-muted mt-1 fw-bold fs-7">Last 24 hours</span>
      </h3>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3">
      <!--begin::Table container-->
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle gs-0 gy-4">
          <!--begin::Table head-->
          <thead>
            <tr class="fw-bolder text-muted bg-light">
              <th class="ps-4 min-w-300px rounded-start">Ad</th>
              <th class="min-w-125px">Spend</th>
              <th class="min-w-125px">ROAS</th>
              <th class="min-w-200px">CPA</th>
              <th class="min-w-150px">Action</th>
              <th class="min-w-200px text-end rounded-end"></th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template v-for="(item, index) in list" :key="index">
              <tr>
                <td>
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-50px me-5">
                      <span class="symbol-label bg-light">
                        <img
                          :src="item.ad.image"
                          class="h-75 align-self-end"
                          alt=""
                        />
                      </span>
                    </div>

                    <div class=" d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        class="text-hover-primary text-dark mb-1 fs-6"
                        >{{ item.ad.name }}</a
                      >
                    </div>
                  </div>
                </td>

                <td>
                  <span
                    href="#"
                    class="text-muted fw-bolder d-block mb-1 fs-6"
                    >{{ $filters.money(item.stats.spend, 0) }}</span
                  >
                </td>

                <td>
                  <span
                    href="#"
                    class="text-muted fw-bolder d-block mb-1 fs-6"
                    >{{ $filters.number({value: item.stats.roas, decimals: 2}) }}</span
                  >
                </td>

                <td>
                  <span
                    href="#"
                    class="text-muted fw-bolder d-block mb-1 fs-6"
                    >{{ $filters.money(item.stats.cpa, 0) }}</span
                  >
                </td>

                <td>
                  <span class="text-dark fw-bold d-block fs-7 mt-1">
                    <i v-if="item.action.name === 'Paused'" class="fa fa-solid fa-pause me-2 text-danger"></i>
                    <i v-if="item.action.name === 'Started'" class="fa fa-solid fa-play me-2 text-success"></i>
                    {{ item.action.name }}</span>
                </td>

                <td class="text-end">
                  <a
                    href="#"
                    class="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                  >
                    See details
                  </a>

<!--                  <a-->
<!--                    href="#"-->
<!--                    class="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4"-->
<!--                  >-->
<!--                    Activate-->
<!--                  </a>-->
                </td>
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
  <!--end::Tables Widget 12-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-widget-12",
  components: {
  },
  props: {
    widgetClasses: String
  },
  setup() {
    const list = [
      {
        ad: {
          image: "https://picsum.photos/id/1/100/100",
          name: "Slim T - Sales Catalog",
        },
        stats: {
          spend: 5400,
          roas: 1.74,
          cpa: 107
        },
        action: {
          name: "Paused",
        }
      },
      {
        ad: {
          image: "https://picsum.photos/id/2/100/100",
          name: "| Type:quick slideshow | Other::Nipkit Casual Shorts | Product_id:##1961429434410## |",
        },
        stats: {
          spend: 2894,
          roas: 2.45,
          cpa: 83
        },
        action: {
          name: "Started",
        }
      },
      {
        ad: {
          image: "https://picsum.photos/id/3/100/100",
          name: "| type:slideshow | - Copy",
        },
        stats: {
          spend: 852,
          roas: 3.74,
          cpa: 38
        },
        action: {
          name: "Started",
        }
      },
      {
        ad: {
          image: "https://picsum.photos/id/4/100/100",
          name: "image - Copy",
        },
        stats: {
          spend: 2984,
          roas: 1.93,
          cpa: 150
        },
        action: {
          name: "Paused",
        }
      },
      {
        ad: {
          image: "https://picsum.photos/id/5/100/100",
          name: "ns | Burger Embroidered T-Shirt",
        },
        stats: {
          spend: 92,
          roas: 5.33,
          cpa: 12
        },
        action: {
          name: "Paused",
        }
      },
      {
        ad: {
          image: "https://picsum.photos/id/6/100/100",
          name: "all without STOP - Copy 2",
        },
        stats: {
          spend: 8397,
          roas: 2.44,
          cpa: 48
        },
        action: {
          name: "Started",
        }
      },
      {
        ad: {
          image: "https://picsum.photos/id/7/100/100",
          name: "image 1 Cassio Linen Blend Shirt",
        },
        stats: {
          spend: 29572,
          roas: 3.29,
          cpa: 49
        },
        action: {
          name: "Paused",
        }
      },
    ];

    return {
      list
    };
  }
});
</script>
