
import {defineComponent} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {mapActions, mapGetters} from "vuex";
import DashboardMetrics from './dashboard/Metrics.vue';
// import DashboardActionLog from './dashboard/ActionLog.vue';
import InvalidShop from '@/components/shopify/InvalidShop.vue';
// import AdStatusesMetric from "@/views/dashboard/Metrics/AdStatusesMetric.vue";
import CPA from "@/views/dashboard/Metrics/CPA.vue";
import SalesByHour from "@/views/dashboard/Metrics/SalesByHour.vue";
import ProductsTable from "@/views/dashboard/Metrics/ProductsTable.vue";
import CampaignTable from "@/views/dashboard/Metrics/CampaignTable.vue";
import Sales from "@/views/dashboard/Metrics/Sales.vue";
import Roas from "@/views/dashboard/Metrics/Roas.vue";
import TopSources from "@/views/dashboard/Metrics/TopSources.vue";
import UTMTable from "@/views/dashboard/Metrics/UtmTable.vue";
import MetricChartCard from "@/components/cards/MetricChartCard.vue";
import MixedWidget10 from "@/unused/components/widgets/mixed/Widget10.vue";
import AdsTable from "@/views/dashboard/AdsTable.vue";


export default defineComponent({
  name: "dashboard",
  data() {
    return {
      metrics: [
        {
          title: 'ROAS',
          value: 2.15,
          diff: 0.37,
          numberType: 'integer',
          data: [1.8, 2.34, 2.13, 1.90, 2.66, 1.4, 2.19]
        },
        {
          title: 'Blended ROAS',
          value: 3.35,
          diff: -0.17,
          numberType: 'integer',
          data: [2.31, 4.45, 2.54, 1.06, 3.9, 1.32, 5.82]
        },
        {
          title: 'Spend',
          value: 1048,
          diff: 1.32,
          numberType: 'money',
          data: [816.12, 1344.59, 935.19, 938.37, 833.49, 698.51, 1017.89]
        },
        {
          title: 'NCPA',
          value: 34.43,
          diff: 0.87,
          numberType: 'money',
          data: [51.61, 24.54, 50.17, 28.14, 26.69, 57.19, 56.63]
        },
        {
          title: 'CPA',
          value: 20.34,
          diff: -0.23,
          numberType: 'money',
          data: [51.61, 24.54, 50.17, 28.14, 26.69, 57.19, 56.63]
        },
        {
          title: 'Orders',
          value: 395,
          diff: 2.43,
          numberType: 'integer',
          data: [167, 110, 137, 201, 457, 429, 242]
        }
      ],
      actionStats: [
        {
          title: 'Paused',
          icon: '/media/svg/icons/Media/Pause.svg',
          value: 7,
          description: '$32K Spend Last 7 days'
        },
        {
          title: 'Resumed',
          icon: '/media/svg/icons/Media/Play.svg',
          value: 15,
          description: '$13K Spend Last 7 days'
        },
        {
          title: 'Budget change',
          icon: '/media/svg/icons/Shopping/Dollar.svg',
          value: 4,
          description: '$3K Spend Last 7 days'
        },
        {
          title: 'Bid change',
          icon: '/media/svg/icons/Shopping/Dollar.svg',
          value: 2,
          description: '$18K Spend Last 7 days'
        },
      ]
    }
  },
  components: {
    DashboardMetrics,
    InvalidShop,
    // AdStatusesMetric,
    CPA,
    SalesByHour,
    ProductsTable,
    CampaignTable,
    Sales,
    Roas,
    TopSources,
    UTMTable,
    MetricChartCard,
    AdsTable
  },

  computed: {
    ...mapGetters({
      selectedShopifyShop: 'shopify/shops/selected',
    }),
  },

  methods: {
    ...mapActions({
      fetchStats: 'shopify/shops/dashboard/fetchStats',
      fetchKeyMetrics: 'shopify/shops/dashboard/fetchKeyMetrics'
    }),
  },

  watch: {
    'selectedShopifyShop.id'() {
      this.fetchStats({force: true});
      this.fetchKeyMetrics();
    }
  },

  mounted() {
    setCurrentPageTitle("Dashboard");
    this.fetchStats({force: true});
    this.fetchKeyMetrics();
  },
});
